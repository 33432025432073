import styled from "styled-components";

export const Container = styled.div`
    display: grid;
    place-items: center;
    grid-row-gap: 20px;
    color: white;
    margin: 60px 0 20px;
`

export const Therapists = styled.div`
    display: grid;
    grid-template-rows: auto;
    grid-row-gap: 40px;
    margin: 30px 0;
    
    @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
        grid-column-gap: 20px;
    }
`

export const Therapist = styled.div`
    display: grid;
    padding: 0 0 60px;
    border-bottom: 1px solid white;
    grid-row-gap: 20px;
    align-content: start;
    
    &:last-child {
        padding: 0;
        border-bottom: none;
    }
    
    @media (min-width: 768px) {
        grid-template-row: auto;
        width: 100%;
        place-items: center;
        padding: 0;
        border-bottom: none;
    }
    
    @media (min-width: 900px) {
        grid-template-columns: auto 1fr;
        width: 100%;
        grid-column-gap: 20px;
        place-items: unset;
    }
`

export const TherapistInfo = styled.div`
    display: grid;
    grid-template-rows: auto;
    place-items: center;
    grid-row-gap: 5px;
    
    @media (min-width: 768px) {
        align-content: center;
    }
    
    @media (min-width: 900px) {
       place-items: start;
    }
    
    h3 {
        border-bottom: 1px solid #979797;
        padding-bottom: 8px;
        margin: 0;
        color: #FF7600;
    }
    
    h4 {
        margin: 0 0 20px;
        color: #AFAFAF;
    }
    
    p {
        margin: 0;
        color: #AFAFAF;
        font-size: .875rem;
    }
`

export const TherapistProfilePhoto = styled.div`
    display: grid;
    width: 200px;
    height: 200px;
    place-items: center;
    border-radius: 100%;
    position: relative;
    margin: 32.5px;
    
    > div {
        position: absolute;
        border: 1px dashed rgba(70,70,70,1);
        padding: 15px;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        
        div {
            overflow: hidden;
            box-sizing: border-box;
            border-radius: 100%;
            width: 100%;
            height: 100%;
        }
    }
    
     @media (max-width: 1079px) and (min-width: 900px) {
        width: 150px;
        height: 150px;
    }
    
    img {
        width: 100%;
        height: 100%;
    }
    
    &::before,
    &::after {
        content: "";
        border: 1px dashed rgba(70,70,70,.65);
        width: calc(100% + 50px);
        height: calc(100% + 50px);
        position: absolute;
        border-radius: 100%;
    }
    *, 
    &::before,
    &::after {
        box-sizing: unset;
    }
    
    &::after {
        border: 1px dashed rgba(70,70,70,.35);
        width: calc(100% + 65px);
        height: calc(100% + 65px);
    }
`

export const Services = styled.div`
    display: grid;
    grid-template-rows: auto;
    grid-row-gap: 20px;
    margin: 30px 0;
    padding: 0;
    
    @media (min-width: 768px) {
       grid-template-columns: repeat(3, auto);
       grid-column-gap: 20px;
    }
`

export const Service = styled.div`
    display: grid;
    background: white no-repeat;
    border-radius: 12px;
    
    background-size: 330px;
    background-position: top center;
    overflow: hidden;
    box-sizing: border-box;
    
    .imageContainer {
        width: 100%;
        overflow: hidden;
        place-self: center;
        position: relative;
        padding-top: 110%;
    }
    
    img {
        height: 100%;
        position: absolute;
        top: 0;
    }
    
    @media (min-width: 767px) {
        place-content: start;
    }
`

export const ServiceInfo = styled.div`
    display: grid;
    color: black;
    padding: 20px;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    margin-top: -60px;
    
    p {
        margin: 10px;
    }
    
    background: linear-gradient(
        to top,
        white 75%,
        rgba(255,255,255,.35) 100%
    );
`
import styled from "styled-components";

export const Icon = styled.div`
    width: 60px;
    height: 60px;
    background-position: center center;
    background-repeat: no-repeat;
    margin: 20px 8px;
    
    @media (min-width: 835px) {
        margin: 20px 16px;
    }
 `

export const Techniques = styled.div`
     font-weight: bolder;
     color: white;
     margin: 30px 0 24px;
     text-align: center;
     
     media (min-width: 768px) {
         text-shadow: 0px 3px 10px rgba(0,0,0,.75);
     }

     p {
         line-height: 1rem;
         display: inline-grid;
         // text-transform: uppercase;
         margin: 0;
     }
     
     ul {
         display: grid;
         padding: 0;
         margin: 0;
         align-items: start;
         place-content: center;
         
         @media (max-width: 767px) {
             grid-template-columns: 1fr 1fr;
         }
         
         @media (min-width: 768px) {
             grid-template-columns: repeat(8, min-content);
             grid-column-gap: 3px;
         }
         
         @media (min-width: 1080px) {
             grid-template-columns: repeat(8, 1fr);
             grid-column-gap: 5px;
             padding: 0 100px;
         }
         
         li {
             margin: 0;
             display: grid;
             place-items: center;
             grid-template-rows: auto auto;
             list-style-type: none;
             
             p {
                 font-size: .75rem;
             }
         }
     }
 `
import styled from "styled-components";

export const BookSession = styled.div`
    display: grid;
    grid-template-rows: auto auto auto;
    font-size: 2rem;
    margin: 15px -15px 0;
    // overflow: hidden;
    
     @media (min-width: 768px) {
          margin: 10px 0 0;
     }
`

export const SessionPricing = styled.div`
    display: grid;
    grid-template-rows: auto auto;
    background: white;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    padding: 10px;
    background: white;
    place-items: center;
    z-index: 3;
    grid-row-gap: 10px; 
    
    @media (min-width: 600px) {
        grid-row-gap: 20px; 
        padding: 15px;
    }
`

export const Sessions = styled.div`
    display: grid;
    grid-template-columns: auto auto auto;
    font-size: 1rem;
    
    @media (max-width: 459px) {
        place-items: center;
        grid-column-gap: 30px; 
    }
    
    @media (min-width: 460px) {
        grid-template-columns: auto auto auto;
        grid-column-gap: 10px;
        grid-row-gap: unset;
        width: auto;
        place-items: center;
    }
    
    @media (min-width: 600px) {
        grid-column-gap: 35px; 
    }
    
    @media (min-width: 1080px) {
        grid-column-gap: 50px; 
    }
    
    label {
        color: #FF7600;
        font-size: .75rem;
        font-weight: lighter;
        
        @media (min-width: 768px) {
            font-size: 1rem;
        }
    }
    
    > div {
        display: grid;
        grid-template-rows: 1fr auto;
        place-items: end;
        grid-column-gap: 12px; 
        
        // @media (min-width: 600px) {
        //     grid-column-gap: 10px; 
        // }
        
        @media (min-width: 460px) {
            grid-template-columns: 1fr auto;
            place-items: unset;
            grid-column-gap: 10px; 
        }
        
        @media (min-width: 768px) {
            place-items: end;
        }
        
        > div {
            @media (max-width: 459px) {
                width: 100%;
                display: grid;
                text-align: center;
            }
        }
    }
`

// export const TimeLabel = styled.div`
//     display: grid;
//     place-items: center;
//     color: #FF7600;
//     font-size: 2rem;
//     letter-spacing: -.1rem;
//
//     @media (min-width: 768px) {
//         font-size: 5rem;
//         font-weight: lighter;
//         height: 77px;
//
//     }
//
//     @media (min-width: 1080px) {
//         font-size: 6.25rem;
//         height: 96px;
//     }
// `

// export const Price = styled.div`
//     display: grid;
//     place-items: center;
//     color: white;
//     background: #4C4C4C;
//     padding: 2px 4px;
//     border-radius: 5px;
//     font-size: .875rem;
//     font-weight: lighter;
//
//     @media (min-width: 768px) {
//         font-size: 1.875rem;
//         font-weight: 300;
//     }
// `


export const BookSessionButtonContainer = styled.div`
    display: grid;
    z-index: 2;
    background: white;
    place-content: center;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    box-shadow: 0px 6px 6px rgba(0,0,0,.5);
    padding: 5px 0 20px;
    
    @media (min-width: 768px) {
        padding: 0;
    }
`


export const BookSessionButton = styled.button`
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 10px; 
    place-items: center;
    font-weight: bolder;
    background: #F06E00;
    border: 1px solid #BD5600;
    border-radius: 26px;
    cursor: pointer;
    color: white;
    padding: 10px 30px;
    font-size: 1rem;
    z-index: 2;
    position: relative;
    transition: all .25s ease-out;
    
    &:hover {
        background: #BD5600;
    }
    
    @media (min-width: 768px) {
        top: -24px;
    }
`

export const TabLeftSkew = styled.div`
    top: 0;
    left: -48px;
    position: absolute;
    height: 48px;
    width: 50px;
    
    &::before,
    &::after {
        content: "";
        position: absolute;
        vertical-align:top;
        display: inline-block;
        transform-origin: top right;
        transform: skew(33deg);
        background: white;
    }

    &::before {
        width: 12px;
        height: 12px;
        background: transparent;
        background: radial-gradient(circle at bottom left, transparent 68%, white 73%);
        left: -12px;
    }
    
    &::after {
        top: 0;
        left: -1px;
        height: 100%;
        width: 100%;
        border-radius: 0 0 0 12px;
    }
    
    @media (max-width: 767px) {
        display: none;
    }
`
export const TabRightSkew = styled.div`
    top: 0;
    right: -48px;
    position: absolute;
    height: 48px;
    width: 50px;
    
    &::before,
    &::after {
        content: "";
        position: absolute;
        vertical-align:top;
        display: inline-block;
        transform-origin: top left;
        transform: skew(-33deg);
        background: white;
    }

    &::before {
        width: 12px;
        height: 12px;
        background: transparent;
        background: radial-gradient(circle at bottom right, transparent 68%, white 73%);
        right: -12px;
    }
    
    &::after {
        top: 0;
        right: -1px;
        height: 100%;
        width: 100%;
        border-radius: 0 0 12px 0;
    }
    
    @media (max-width: 767px) {
        display: none;
    }
`

export const Tab = styled.div`
    background: white;
    height: 48px;
    width: 100%;
    position: relative;
    
    @media (min-width: 768px) {
        width: auto;
        bottom: -47px;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }
    
    @media (min-width: 1080px) {
        padding: 0 25px;
    }
`
import styled from "styled-components";

export const Status = styled.div`
    background-color: rgba(76,76,76,.8);
    border-radius: 12px;
    color: white;
    padding: 20px 0 0;
    
    .status-bar {
        display: grid;
        grid-template-columns: 1fr auto;
    }
    
    .currentDay,
    .operationStatus {
        color: #3CE700;
    }
    
    .currentDay.closed,
    .closed .operationStatus {
        color: #FF0000;
    }
`

export const OperationHours = styled.div`
    font-size: .875rem;
    color: white;
    padding: 10px 0 10px 15px;
    cursor: pointer;
    display: grid;
    grid-template-columns: auto auto;
    place-content: start;
    align-content: center;
    align-items: center;
    line-height: 1.125rem;
    letter-spacing: -.025rem;
    
    svg {
        top: -6px;
        position: relative;
        transition: all .25s ease-out;
        font-size: 1.875rem;
    }
    
    @media (min-width: 400px) {
        padding: 0 0 0 15px;
    }

    @media (min-width: 525px) {
        font-size: 1rem;
    }
    
    .seeMore {
        max-height: 18px;
        display: grid;
        font-size: .75rem;
        color: #c4c4c4;
        align-items: start;
        place-content: start;
        grid-template-columns: auto auto;
    }
    
    > div > div {
        display: grid;
        grid-template-rows: auto auto;
        grid-column-gap: 5px;
        
        // @media (min-width: 525px) and (max-width: 767px) {
        //     grid-template-columns: auto auto;
        //     grid-column-gap: 5px;
        // }
        // @media (min-width: 800px) {
        //     grid-template-columns: auto auto;
        //     grid-column-gap: 5px;
        // }
        
        .separator {
            display: none;
        }
        
        @media (min-width: 400px) {
            grid-template-columns: auto auto;
            
            .separator {
                display: inline;
            }
        }
        
        span:first-child {
            margin: 0;
            padding: 0;
            // color: #3CE700;
            font-weight: 700;
        }
    }
`

export const Contact = styled.div`
    display: grid;
    grid-template-columns: auto auto auto;
    grid-column-gap: 0;
    font-weight: bolder;
    color: #FF7600;
    padding: 0 15px 0 0;
    
    .message {
        padding: 12px;
        cursor: pointer;
        display: grid;
        color: #4EC2FC;
        
        &:hover {
            color: white;
        }
    }
    .phone {
        color: #3CE700;
        padding: 0;
        display: grid;
        grid-template-columns: auto auto;
        place-items: center;
        text-decoration: none;
        
        &:hover {
            color: white;
        }
    }
    .map {
        padding: 12px;
        cursor: pointer;
        display: grid;
        
        &:hover {
            color: white;
        }
    }

    .phoneIcon {
        padding: 8px;
    }
    
    > div {
        display: grid;
        grid-template-columns: auto auto;
        place-items: center;
    }
    
    p {
        font-size: 1rem;
        font-weight: normal;
    }
    
    @media (max-width: 899px) {
        .message,
         .map {
            padding: 12px;
        }
    }
`

export const WeeklyHours = styled.div`
    padding: 0 20px 20px;
    font-size: 1rem;
    display: grid;
    grid-template-rows: auto;
    grid-row-gap: 5px;
    
    > div div {
        display: grid;
        grid-template-columns: 60px 1fr;
        grid-row-gap: 5px;
        padding: 0 5px;
    }
    .currentDay {
        display: grid;
        // grid-template-columns: auto 1fr;
        grid-column-gap: 20px;
        
        font-weight: 700;
        border: 1px solid #3CE700;
        padding: 5px 10px;
        margin-left: -10px;
        border-radius: 17px;
        place-self: start;
        place-items: center;
        
        &.closed {
            border: 1px solid #FF0000;
        }
        
        @media (min-width: 550px) {
            grid-template-columns: auto 1fr;
            place-items: unset;
        }
    }
`

export const Drawer = styled.div`

`

export const DrawerContent = styled.div`
`

export const Message = styled.div`
    display: grid;
    padding: 8px;
    grid-template-rows: auto;
    grid-row-gap: 10px;
    text-align: left;
    font-size: 1rem;
    
    @media (min-width: 600px) {
        width: 500px;
    }
    
    h3 {
        margin: 8px;
        text-align: left;
    }
    
    .message-field {
        padding: 10px;
    }
`
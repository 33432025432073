import React, {useContext, useEffect, useState} from "react";
import * as Styled from "./status-styled-component";
import Data from "../../data";
import {
    FormControl,
    InputLabel,
    Input,
    FormHelperText,
    TextareaAutosize,
    InputAdornment,
    TextField,
    OutlinedInput,
    IconButton,
    Button,
    Collapse
} from '@mui/material';
import {
    AccountCircle,
    AlternateEmailOutlined,
    CallOutlined,
    PlaceOutlined,
    MessageOutlined,
    ExpandMore
} from '@mui/icons-material';
import GoogleMap from "../map/map";
import ScrollIntoView from 'react-scroll-into-view';
import {AppCtx} from "../../contexts/GlobalContext";

interface weeklyHours {
    id: number,
    day: string,
    hours: string,
    open: any,
    close: any
}


function StatusModule(props: any) {
    const appContext = useContext(AppCtx);
    const statusbarRef = React.useRef<HTMLDivElement>(null);
    const drawerContentRef = React.useRef<HTMLDivElement>(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [showHours, setShowHours] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const [prevScrollTop, setPrevScrollTop] = useState(0);

    const [weeklyHours, setWeeklyHours] = useState<weeklyHours[]>([]);
    const [currentDay, setCurrentDay] = useState(0);
    const [currentHour, setCurrentHour] = useState(0);
    const [isSaturday, setIsSaturday] = useState<boolean>(false);
    const [currentlyOpen, setCurrentlyOpen] = useState(false);
    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 900px)").matches
    );

    useEffect(() => {
        window
            .matchMedia("(min-width: 900px)")
            .addEventListener('change', e => setMatches(e.matches));
    }, []);

    useEffect(() =>{
        const clonedHoursArray = [...Data.hours];

        for (let i=0; i<currentDay; i++) {
            clonedHoursArray.push(clonedHoursArray.shift()!);
        }
        // If current day is Sunday set weekly hours array to original Data.hours array
        // Else set weekly hours array to shifted array
        setWeeklyHours(currentDay != 0 ? clonedHoursArray : Data.hours);
    }, [currentDay]);

    useEffect(() => {
        const date = new Date();
        const day: number = date.getDay();
        // const day: number = 5;
        // Sunday - Saturday : 0 - 6
        const hour: number = date.getHours();
        // const hour: number = 19;
        setCurrentDay(day);
        setCurrentHour(hour);

        interface Number {
            between(a: number, b: number): number;
        }

        //@ts-ignore
        Number.prototype.between = function(a: number, b: number, inclusive: boolean) {
            let min = Math.min.apply(Math, [a, b]),
                max = Math.max.apply(Math, [a, b]);
            return inclusive ? this >= min && this <= max : this > min && this < max;
        };
        //@ts-ignore
        const notSunday = day.between(1, 6, true);
        //@ts-ignore
        const openHours = hour.between(8, day === 6 ? 15 : 19, false);
        //@ts-ignore
        const isOpen = notSunday && openHours;
        //@ts-ignore
        // console.log("OPEN >", isOpen);

        // day(1-5) && hour(8 - 19) || day(6) && hour(8-15) = open : close
        //@ts-ignore
        setCurrentlyOpen(isOpen);
        setIsSaturday(day === 6);

        // console.log("DAY :", day, "HOUR :", hour);
    }, []);

    const scrollIntoView = () => {
        // d = Distance of Top of viewport to Element
        const offsetTop = drawerContentRef.current!.getBoundingClientRect().top;
        // s = distance scrolled from Top of viewport
        const scrollTop = window.scrollY;
        // eh = element height
        const drawerHeight = drawerContentRef.current!.clientHeight;
        // vh = viewport height
        const viewportHeight = window.innerHeight;
        const statusBarHeight = statusbarRef.current!.clientHeight;
        // element + statusbar + nav height
        const combinedHeight = drawerHeight + statusbarRef.current!.clientHeight + 70;

        // combinedHeight > window height

        // console.log("VIEWPORT HEIGHT >> ", viewportHeight);
        // console.log("OFFSET >> ", offsetTop);
        // console.log("DRAWER HEIGHT >> ", drawerHeight);
        // console.log("DRAWER and OFFSET HEIGHT >> ", drawerHeight + offsetTop);
        // console.log("STATUS HEIGHT", statusbarRef.current!.clientHeight);
        // console.log("COMBINED HEIGHT >> ", combinedHeight);

        // (drawerHeight + offsetTop > viewportHeight) && (combinedHeight > viewportHeight)  ? scroll window to top of status bar : scroll to bottom of element

        // if(!drawerOpen) {
        //     window.scrollTo({
        //         top: prevScrollTop,
        //         left: 0,
        //         behavior: 'smooth'
        //     });
        //     return;
        // }

        if ((drawerHeight + offsetTop) > viewportHeight) {
            if (combinedHeight > viewportHeight) {
                window.scrollTo({
                    top: (statusbarRef.current!.getBoundingClientRect().top + scrollTop) - 70,
                    left: 0,
                    behavior: 'smooth'
                });
            } else {
                window.scrollTo({
                    top: (offsetTop - drawerHeight) + 70,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        }
    };

    function OperationHoursStatus() {
        // Format
        // Open - Closes 7pm
        // Closed - Opens (day = 6: Monday) 8am

        const opDayHours = () => {
            // Show current day opening hour if current hour is less than current day opening hour
            if (currentHour < Data.hours[currentDay].open!) {
                return `today ${Data.hours[currentDay].open!}${Data.hours[currentDay].open! < 12 ? "am" : "pm"}`;
            }
            // Show next day opening hour if current hour is greater than or equal to current day closing hour
            else if (currentHour >= Data.hours[currentDay].close!) {
                return `${Data.hours[isSaturday ? 1 : currentDay+1].day} ${Data.hours[isSaturday ? 1 : currentDay+1].open!}${Data.hours[currentDay].open! < 12 ? "am" : "pm"}`;
            }
            // Show current day closing hour
            else if (Data.hours[currentDay].close!) {
                return `${Data.hours[currentDay].close! - (Data.hours[currentDay].close! > 12 ? 12 : 0)}${Data.hours[currentDay].close! < 12 ? "am" : "pm"}`;
            }
        };

        return (
            <div className={currentlyOpen ? 'open' : 'closed'}>
                <span className={"operationStatus"}>{currentlyOpen ? "Open" : "Closed"}</span>
                <span>
                    <span className={"separator"}>·</span> {!currentlyOpen ? 'Opens' : 'Closes'} {opDayHours()}
                </span>
            </div>
        );
    }

    function WeeklyHours() {
        return (
            <Styled.WeeklyHours>
                <h3>Hours</h3>

                {React.Children.toArray(
                    weeklyHours.map((hour, i) => {
                            // console.log("HOUR ID :", hour);
                            // console.log("CURRENT DAY :", currentDay);
                            const current = hour.id === currentDay ? 'currentDay' : '';

                            return <div className={`${current} ${!currentlyOpen ? 'closed' : ''}`}>
                                <div><span>{hour.day}</span> {hour.id !== 0 ? <span>{hour.open}{`${hour.open! < 12 ? 'am':'pm'} - ${ hour.close! - (hour.close! > 12 ? 12:0)}${hour.close! < 12 ? 'am':'pm'}` }</span> : 'Closed'}</div>
                                {
                                    current && <span>Currently {!currentlyOpen ? 'Closed' : 'Open'}</span>
                                }
                            </div>
                        // return(<></>);
                        }
                    ))}
            </Styled.WeeklyHours>
        );
    }

    return (
        <Styled.Status className="grid-area-status">
            <div className="status-bar" ref={statusbarRef}>
                <Styled.OperationHours onClick={() => {
                    showMap && setShowMap(false);
                    showMessage && setShowMessage(false);
                    setShowHours(true);

                    if (!drawerOpen || showHours) {
                        setDrawerOpen(!drawerOpen);
                    }
                }}>
                    <div>
                        <OperationHoursStatus/>
                        <span className={"seeMore"}>
                            {(!showMap && !showMessage) ?
                                <>
                                    {drawerOpen ? "Hide" : "See more"} hours
                                    < ExpandMore sx={{
                                        color: "#FF7600",
                                        fontSize: 12,
                                        transform: `rotate(${drawerOpen ? "-.5turn" : "0turn"})`
                                    }}/>
                                </>
                                :
                                <>Click to view hours</>
                            }
                        </span>
                    </div>

                </Styled.OperationHours>

                <Styled.Contact>
                    <div className="map"
                         onClick={() => {
                             showHours && setShowHours(false);
                             showMessage && setShowMessage(false);
                             setShowMap(true);

                             if (!drawerOpen || showMap) {
                                 setDrawerOpen(!drawerOpen);
                             }
                         }}>
                        <PlaceOutlined sx={{fontSize: 28}}/>
                    </div>

                    {
                        appContext!.navMessageButton! &&
                        <div className="message" onClick={() => {
                            showHours && setShowHours(false);
                            showMap && setShowMap(false);
                            setShowMessage(true);

                            if (!drawerOpen || showMessage) {
                                setDrawerOpen(!drawerOpen);
                            }

                            if (showMessage) {
                                scrollIntoView();
                            }

                        }}>
                            <MessageOutlined sx={{fontSize: 28}} className="messageIcon"/>
                        </div>
                    }

                    <a className="phone"
                        rel=""
                        role="link"
                        href={`tel:${props.statusData.phoneNumber.replace(/-|\s/g,"")}`}
                        aria-label={`Call ${props.statusData.phoneNumber}`}
                    >
                        <CallOutlined sx={{fontSize: 44}} className="phoneIcon"/>
                        {matches && <p>{props.statusData.phoneNumber}</p>}
                    </a>
                </Styled.Contact>
            </div>


            <Styled.Drawer
                className={`drawer ${drawerOpen ? "open" : "close"}`}
                ref={drawerContentRef}
            >
                <Collapse
                    in={drawerOpen}
                    timeout={200}
                    onEntered={(node, isAppearing) => {
                        setPrevScrollTop(window.scrollY);
                        if (!isAppearing) scrollIntoView();
                    }}
                    onExit={(node) => {
                        scrollIntoView();
                    }}
                    onExited={() => {
                        setShowHours(false);
                        setShowMessage(false);
                        setShowMap(false);
                    }}
                >
                    <Styled.DrawerContent>
                        {
                            showHours &&
                            <WeeklyHours/>
                        }

                        {
                            showMessage &&
                            <Styled.Message id="message">

                                <h3>Send Message</h3>
                                <FormControl>
                                    <TextField
                                        required

                                        label="Name (First, Last)"
                                        id="name"
                                        sx={{m: 1, width: 'auto', input: {color: 'white', borderColor: 'white'}}}
                                        // InputProps={{
                                        //     startAdornment:
                                        //         <InputAdornment position="start">
                                        //             <AccountCircle/>
                                        //         </InputAdornment>,
                                        // }}
                                    />
                                </FormControl>

                                <FormControl sx={{m: 1, width: 'auto'}} variant="outlined" required>
                                    <InputLabel htmlFor="outlined-adornment-password">Email</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        // type={values.showPassword ? 'text' : 'password'}
                                        // value={values.password}
                                        // onChange={handleChange('password')}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    // onClick={handleClickShowPassword}
                                                    // onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {/*{values.showPassword ? <VisibilityOff /> : <Visibility />}*/}
                                                    <AlternateEmailOutlined/>
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Password"
                                    />
                                    <FormHelperText id="my-helper-text">We'll never share your
                                        email.</FormHelperText>
                                </FormControl>

                                <FormControl sx={{m: 1, width: 'auto'}} variant="outlined" required>
                                    <InputLabel htmlFor="outlined-adornment-password">Phone</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        // type={values.showPassword ? 'text' : 'password'}
                                        // value={values.password}
                                        // onChange={handleChange('password')}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    // onClick={handleClickShowPassword}
                                                    // onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {/*{values.showPassword ? <VisibilityOff /> : <Visibility />}*/}
                                                    <CallOutlined/>
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Phone"
                                    />
                                    <FormHelperText id="phone-helper-text">ex. 555-555-5555</FormHelperText>
                                </FormControl>

                                <TextField
                                    sx={{m: 1, width: 'auto'}}
                                    required
                                    id="outlined-multiline-static"
                                    label="Message"
                                    multiline
                                    rows={6}
                                    defaultValue=""
                                />

                                <Button sx={{m: 1, width: '25ch'}} variant="contained">Send Message</Button>
                                <Button sx={{m: 1, width: '25ch'}} variant="contained"
                                        onClick={() => setDrawerOpen(false)}>Cancel</Button>
                            </Styled.Message>
                        }

                        {
                            showMap &&
                            <GoogleMap/>
                        }

                    </Styled.DrawerContent>
                </Collapse>
            </Styled.Drawer>
        </Styled.Status>
    );
}

export default StatusModule;
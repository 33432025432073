import React from "react";
import * as Styled from "./techniques-styled-comp";
import {Typography} from "@mui/material";
// @ts-ignore
import { motion } from "framer-motion/dist/framer-motion";
import {Variants} from "framer-motion";

function Techniques(props: any) {
    const jumpIn = true;
    const container: Variants = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.075
            }
        }
    }
    const item: Variants = {
        hidden: { opacity: 0, y: jumpIn ? 75 : 0 },
        show: { opacity: 1, y: 0 }
    }

    return (
        <Styled.Techniques className="grid-area-techniques">
            <motion.p
                layout
                initial={{opacity: 0 }}
                animate={{opacity: 1 }}
                transition={{ type: "spring", duration: 2 }}
            >
                {props.title}
            </motion.p>
            <motion.ul
                variants={container}
                initial="hidden"
                animate="show"
            >
                {
                    props.modalities &&
                    React.Children.toArray(
                        props.modalities.map((modality: any) => {
                            return (
                                <motion.li variants={item}>
                                    <Styled.Icon>
                                        <img
                                            src={modality.featuredImage.node.mediaItemUrl}
                                            alt={modality.title}
                                            width={modality.featuredImage.node.mediaDetails.width}
                                            height={modality.featuredImage.node.mediaDetails.height}
                                        />
                                    </Styled.Icon>
                                    <Typography variant="iconLabel">{modality.title}</Typography>
                                </motion.li>
                            )
                        })
                    )
                }
            </motion.ul>
        </Styled.Techniques>
    );
}

export default Techniques;
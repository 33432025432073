import styled from "styled-components";

export const Hero = styled.div`
    font-weight: bolder;
    color: white;
    text-shadow: 0px 3px 20px rgba(0,0,0,.65);
    
    @media (min-width: 768px) {
        text-align: center;
        margin: 0;
    }
    
    > div:first-child {
        display: grid;
        grid-template-rows: repeat(3, auto);
        margin: 10px 0 15px;
        text-transform: uppercase;
        font-size: 2.5rem;
        line-height: 2.5rem;
        
        span {
            color: #FF7600;
        }
        
        @media (min-width: 768px) {
            place-content: center;
            // font-size: 3.125rem;
            grid-template-rows: none;
            grid-template-columns: repeat(3, auto);
            
            margin: 20px 0 15px;
            
            div {
                font-size: 3rem;
            }
        }
    }
    
    p {
        @media (min-width: 768px) {
            margin: 0;
        }
    }
   
`
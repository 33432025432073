import styled from "styled-components";

export const AppHeader = styled.div`
    width: 100%;
    transition: all .15s ease-in;
    display: grid;
    font-family: 'Lato', sans-serif;
  
    @media (min-width: 1080px) {
        place-items: center;
    }
    
    > div {
        display: grid;
        grid-template-columns: 1fr auto;
        // margin: -20px -30px;
        padding: 12px 30px;
        color: white;
        box-sizing: border-box;
        width: 100%;
    
        @media (min-width: 1080px) {
            // max-width: 1080px;
        }
    }
`

export const HeaderLeft = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    background: transparent;
    color: white;
    grid-column-gap: 20px;
    font-weight: 400;
  
    div {
        font-size: 18px;
        align-items: left;
        display: block;
        font-weight: bolder;
        padding: 0;
        margin: 0;
    }
  
    div span {
        display: block;
        
        // &:nth-child(2n){
        //     font-weight: lighter;
        // }
    }
  
    @media (min-width: 481px) {
        div {
            font-size: 22px;
            display: inline;
        }
    
        div span {
            display: inline;
        }
    }
`

export const HeaderRight = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    background: transparent;
    color: white;
`
import React, {useEffect, useState} from "react";
import * as Styled from "./hero-styled-component";
import {Typography} from "@mui/material";
// @ts-ignore
import { motion } from "framer-motion/dist/framer-motion";


function Hero(props: any) {
    const [heroText, setHeroText] = useState([]);

    useEffect(() => {
        if (props.data) {
            setHeroText(props.data.heroText.split('.' || ' '));
        }
    }, [props.data]);

    return (
        <Styled.Hero className="grid-area-hero">
            <motion.div
                initial={{opacity: 0 }}
                animate={{opacity: 1 }}
                transition={{type: "spring", duration: 1.5 }}
            >
                {
                    React.Children.toArray(
                        heroText.map((text) =>
                            text && <Typography variant="h1">{text}<span className="brandPeriod">.</span></Typography>
                        )
                    )
                }
            </motion.div>

            <motion.div
                initial={{opacity: 0 }}
                animate={{opacity: 1 }}
                transition={{type: "spring", duration: 1, delay: .15 }}
                dangerouslySetInnerHTML={{__html: props.data && props.data.heroDescription}}
            />
        </Styled.Hero>
    );
}

export default Hero;
import React from "react";
import * as Styled from "./book-session-styled-component";
import StatusModule from "../status/status";
import {Typography} from "@mui/material";
import {
    CalendarMonthOutlined
} from '@mui/icons-material';
// @ts-ignore
import { motion } from 'framer-motion/dist/framer-motion';
import {Variants} from "framer-motion";

function BookSession(props: any) {
    const container: Variants = {
        hidden: {opacity: 0},
        show: {
            opacity: 1,
            transition: {
                delay: .15,
                staggerChildren: 0.15,
                when: 'beforeChildren'
            }
        }
    }
    const item: Variants = {
        hidden: {opacity: 0, y: 20},
        show: {
            opacity: 1,
            y: 0,
            transition: {
                duration: .25
            }
        }
    }
    const container2: Variants = {
        hidden: {opacity: 0},
        show: {
            opacity: 1,
            transition: {
                staggerChildren: .25,
                delayChildren: .25
            }
        }
    }
    const sessionLengthFramerMotion: Variants = {
        hidden: {opacity: 0, y: 50},
        show: {opacity: 1, y: 0}
    }
    const sessionPriceFramerMotion: Variants = {
        hidden: {opacity: 0, x: -50},
        show: {opacity: 1, x: 0}
    }
    const bookButton: Variants = {
        hidden: {
            opacity: 0,
            scale: .6,
            zIndex: 2,
            position: "relative",
            y: -10
        },
        show: {
            opacity: 1,
            scale: 1,
            y: 0,
            transition: {
                type: "spring",
                bounce: 0.5,
                duration: .75,
                delay: 1.35
            }
        }
    }
    const statusDrawer: Variants = {
        hidden: {y: -80},
        show: {
            y: 0,
            transition: {
                delay: 1.5,
                duration: .75,
                type: "spring",
                bounce: 0.4
            }
        }
    }

    return (
        <Styled.BookSession className="grid-area-book-session">
            <Styled.SessionPricing>
                <Typography variant="sessionNote">{props.copy && props.copy.sessionsNote}</Typography>

                <Styled.Sessions
                    as={motion.div}
                    variants={container}
                    initial="hidden"
                    animate="show"
                >
                    {
                        props.prices &&
                        React.Children.toArray(
                            props.prices.map((session: any) =>
                                <motion.div
                                    variants={item}
                                >
                                    <Typography
                                        variant="sessionTimeLarge">{session.sessionPrice.sessionLength}</Typography>

                                    <motion.div
                                        variants={container2}
                                    >
                                        <motion.div variants={sessionLengthFramerMotion}>
                                            <Typography
                                                variant="sessionTimeLabel">{session.sessionPrice.sessionLengthLabel}
                                            </Typography>
                                        </motion.div>
                                        <motion.div variants={sessionPriceFramerMotion}>
                                            <Typography variant="sessionPrice">
                                                <span>{session.sessionPrice.sessionPriceCurrencySymbol}</span>
                                                {session.sessionPrice.sessionPrice}
                                            </Typography>
                                        </motion.div>
                                    </motion.div>
                                </motion.div>
                            )
                        )
                    }
                </Styled.Sessions>
            </Styled.SessionPricing>

            <Styled.BookSessionButtonContainer>
                <Styled.Tab>
                    <Styled.TabLeftSkew/>
                    <motion.div
                        variants={bookButton}
                        initial="hidden"
                        animate="show"
                    >
                        <Styled.BookSessionButton
                            className="text-uppercase"
                            onClick={() => window.open(props.copy.bookingUrl, '_blank')}
                        >
                            <CalendarMonthOutlined className="calendarIcon"/>
                            {props.copy.bookSessionButtonLabel}
                        </Styled.BookSessionButton>
                    </motion.div>
                    <Styled.TabRightSkew/>
                </Styled.Tab>
            </Styled.BookSessionButtonContainer>

            <motion.div
                style={{marginTop: '-20px'}}
                variants={statusDrawer}
                initial="hidden"
                animate="show"
            >
                <StatusModule statusData={props.statusData && props.statusData}/>
            </motion.div>
        </Styled.BookSession>
    );
}

export default BookSession;
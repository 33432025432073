import React, {useContext, useEffect, useState} from "react";
import * as Styled from "./app-header-styled-component";
import {AppCtx} from "../../contexts/GlobalContext";
//@ts-ignore
import {motion} from 'framer-motion/dist/framer-motion';
import {Variants} from "framer-motion";


function AppHeader(props: any) {
    const appContext = useContext(AppCtx);
    const [sticky, setSticky] = useState("");
    const [siteName, setSiteName] = useState<any>([]);
    const [siteLogo, setSiteLogo] = useState('');

    // on render, set listener
    useEffect(() => {
        // @ts-ignore
        window.addEventListener("scroll", isSticky);
        return () => {
            // @ts-ignore
            window.removeEventListener("scroll", isSticky);
        };
    }, []);

    useEffect(() => {
        if (props.data) {
            setSiteLogo(props.data.siteLogo.mediaItemUrl);
            setSiteName(props.data.siteName.split(' '));
        }
    }, [props.data]);

    const isSticky = () => {
        /* Method that will fix header after a specific scrollable */
        // @ts-ignore
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop > 0 ? "is-sticky" : "";
        setSticky(stickyClass);
        // console.log(stickyClass);
    };

    const headerLogoContainerMotion: Variants = {
        hidden: {
            opacity: 0,
        },
        show: {
            opacity: 1,
            transition: {
                duration: .25,
                staggerChildren: .15
            }
        }
    }

    const headerLogoMotion: Variants = {
        hidden: {
            opacity: 0,
            scale: .35
        },
        show: {
            opacity: 1,
            scale: 1,
            transition: {
                type: "spring",
                bounce: .5,
                duration: .65
            }
        }
    }

    const headerMotion: Variants = {
        hidden: {
            opacity: 0,
            x: -50
        },
        show: {
            opacity: 1,
            x: 0,
            transition: {
                duration: .25
            }
        }
    }


    return (
        <Styled.AppHeader className={sticky && "sticky-header"}>
            <div>
                <Styled.HeaderLeft
                    as={motion.div}
                    variants={headerLogoContainerMotion}
                    initial="hidden"
                    animate="show"
                >
                    <motion.img
                        variants={headerLogoMotion}
                        src={siteLogo}
                        className="App-logo"
                        width={"46px"}
                        height={"46px"}
                        alt={siteName + ' Logo'}/>
                    <motion.div variants={headerMotion}>
                        <span>{siteName[0]}</span> <span>{siteName[1]} {siteName[2]}</span>
                    </motion.div>
                </Styled.HeaderLeft>

                <Styled.HeaderRight>
                    {
                        appContext!.navAboutUsLink &&
                        <div>About Us</div>
                    }
                </Styled.HeaderRight>
            </div>
        </Styled.AppHeader>
    );
}

export default AppHeader;
import React, {useContext, useEffect, useRef, useState} from "react";
import * as Styled from "./therapists-styled-comp";
import {Typography} from "@mui/material";
// @ts-ignore
import {motion} from 'framer-motion/dist/framer-motion';
import {Variants} from "framer-motion";
import {AppCtx} from "../../contexts/GlobalContext";

function Therapist(props: any) {
    const mediaUrl = props.data.therapistsCustomFields.photo && props.data.therapistsCustomFields.photo.mediaItemUrl;
    const name = props.data.title;
    const license = props.data.therapistsCustomFields.license;
    const licenseNumber = props.data.therapistsCustomFields.licenseNumber;
    const degree = props.data.therapistsCustomFields.degree;
    const other = props.data.therapistsCustomFields.other;

    const container: Variants = {
        hidden: {opacity: 0},
        show: {
            opacity: 1,
            transition: {
                when: "beforeChildren",
                staggerChildren: 0.15
            }
        }
    }
    const item1: Variants = {
        hidden: {opacity: 0, scale: .5},
        show: {opacity: 1, scale: 1}
    }
    const item2: Variants = {
        hidden: {opacity: 0, x: -20},
        show: {opacity: 1, x: 0}
    }

    return (
        <Styled.Therapist
            as={useContext(AppCtx)!.isMobile ? motion.div : ''}
            variants={container}
            initial={"hidden"}
            whileInView={"show"}
            viewport={{once: true, amount: .5}}
            style={{width: '100%'}}
        >
            <Styled.TherapistProfilePhoto
                as={motion.div}
                variants={item1}
                transition={{type: "spring", duration: .5}}
            >
                <div>
                    <div>
                        <img src={mediaUrl} alt={`${name} photo`}/>
                    </div>
                </div>
            </Styled.TherapistProfilePhoto>

            <Styled.TherapistInfo
                as={motion.div}
                variants={item2}
                transition={{type: "spring", duration: .5}}
            >
                <h3>{name}</h3>
                <h4>{license}</h4>

                {licenseNumber && <p>{`License #${licenseNumber}`}</p>}
                {degree && <p>{degree}</p>}
                {other && <p>{other}</p>}
            </Styled.TherapistInfo>
        </Styled.Therapist>
    );
}

function Service(props: any) {
    const service = props.service;
    const mediaUrl = service.featuredImage && service.featuredImage.node.mediaItemUrl;
    const serviceTitle = service.title;
    const serviceContent = service.serviceCustomFields.serviceDescription;

    const serviceItem = {
        hidden: {opacity: 0, y: 100},
        show: {opacity: 1, y: 0}
    }

    return (
        <Styled.Service
            as={motion.div}
            // as={useContext(AppCtx)!.isMobile ? motion.div : ''}
            variants={serviceItem}
            // initial={useContext(AppCtx)!.isMobile ? "hidden" : ''}
            // whileInView={useContext(AppCtx)!.isMobile ? "show" : ''}
            // viewport={useContext(AppCtx)!.isMobile ? {once: true, amount: .5} : ''}
            style={{width: '100%'}}
        >
            <div className="imageContainer">
                <img src={mediaUrl} alt={serviceTitle}/>
            </div>
            <Styled.ServiceInfo>
                <Typography variant="h3">
                    {serviceTitle}
                </Typography>
                <p>{serviceContent}</p>
            </Styled.ServiceInfo>
        </Styled.Service>
    );
}


function Therapists(props: any) {

    const container: Variants = {
        hidden: {opacity: 0},
        show: {
            opacity: 1,
            transition: {
                when: "beforeChildren",
                staggerChildren: 0.15
            }
        }
    }

    const servicesContainer: Variants = {
        hidden: {opacity: 0},
        show: {
            opacity: 1,
            transition: {
                when: "beforeChildren",
                staggerChildren: .15
            }
        }
    }

    const headerMotion: Variants = {
        hidden: {opacity: 0},
        show: {
            opacity: 1,
            transition: {
                duration: .5
            }
        }
    }

    return (
        <Styled.Container className="grid-area-therapist">
            <motion.div
                variants={headerMotion}
                initial="hidden"
                whileInView="show"
                viewport={{once: true, amount: 1}}
            >
                <Typography variant="h2">
                    {props.sectionTitle}<span className="brandPeriod">.</span>
                </Typography>
            </motion.div>

            <Styled.Therapists
                as={!useContext(AppCtx)!.isMobile ? motion.div : ''}
                variants={container}
                whileInView="show"
                initial={"hidden"}
                viewport={{once: true, amount: .5}}
            >
                {
                    props.therapists &&
                    React.Children.toArray(
                        props.therapists.map((data: any) => <Therapist data={data}/>)
                    )
                }
            </Styled.Therapists>

            <Styled.Services
                // as={motion.div}
                as={!useContext(AppCtx)!.isMobile ? motion.div : ''}
                variants={servicesContainer}
                whileInView="show"
                initial={"hidden"}
                viewport={{once: true, amount: .35}}
            >
                {
                    props.services &&
                    React.Children.toArray(
                        props.services.map((service: any) => <Service service={service}/>)
                    )
                }
            </Styled.Services>
        </Styled.Container>
    );
}

export default Therapists;
export const Data = {
    about: "Etiam vitae tellus sollicitudin, volutpat nunc eget, accumsan tortor. Sed sed odio sed massa consectetur tincidunt. In aliquet nulla sed malesuada mattis. Vivamus ut sem sit amet sem lobortis egestas. Duis posuere, neque in dignissim scelerisque, nisl risus ultrices turpis, ac luctus nulla orci posuere ipsum. Maecenas maximus lorem ac eros eleifend, eu maximus leo tincidunt. Ut ultrices, velit ut imperdiet luctus, mauris est accumsan ligula, id feugiat felis ligula vel tellus. Phasellus turpis ipsum, suscipit ac odio sit amet, consequat tristique massa. Integer in convallis metus. Pellentesque laoreet ex et nulla convallis commodo.",
    hours: [
        {
            id: 0,
            day: "Sun",
            hours: "Closed",
            open: null,
            close: null
        },
        {
            id: 1,
            day: "Mon",
            hours: "08:00 am - 07:00 pm",
            open: 8,
            close: 19
        },
        {
            id: 2,
            day: "Tue",
            hours: "08:00 am - 07:00 pm",
            open: 8,
            close: 19
        },
        {
            id: 3,
            day: "Wed",
            hours: "08:00 am - 07:00 pm",
            open: 8,
            close: 19
        },
        {
            id: 4,
            day: "Thu",
            hours: "08:00 am - 07:00 pm",
            open: 8,
            close: 19
        },
        {
            id: 5,
            day: "Fri",
            hours: "08:00 am - 07:00 pm",
            open: 8,
            close: 19
        },
        {
            id: 6,
            day: "Sat",
            hours: "08:00 am - 03:00 pm",
            open: 8,
            close: 15
        }
    ],
    sessions: {
        notice: "All sessions are the same price regardless of techniques used.",
        buttonLabel: "Book A Session",
        bookSessionURL: "https://taylormanualtherapy.janeapp.com/",
        sessions: [
            {
                time: "60",
                price: "85"
            },
            {
                time: "90",
                price: "120"
            },
            {
                time: "120",
                price: "160"
            }
        ]
    },
    therapist: {
        header: "Our Therapist",
        therapists: [
            {
                photoURL: "nathaniel.webp",
                name: "Nathaniel Taylor",
                license: "PA Massage Therapy",
                licenseNumber: "#MSG012562",
                degree: "B.S Kinesiology",
                other: "NASM CPT",
                bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
            },
            {
                photoURL: "chris.webp",
                name: "Chris Macdonald",
                license: "PA Massage Therapy",
                licenseNumber: "#MSG014549",
                degree: "B.S Nutrition",
                other: "",
                bio: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"
            },
            {
                photoURL: "antoinette.webp",
                name: "Antoinette Long",
                license: "",
                licenseNumber: "",
                degree: "",
                other: "",
                bio: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"
            }
        ]
    },
    services: [
        {
            title: "Long Term Relief",
            description: "Each treatment starts with an assessment to find the root cause",
            imageURL: "long-term-relief.webp"
        },
        {
            title: "Improved Range of Motion",
            description: "We can help remove restrictions and open up active ranges of motions.",
            imageURL: "improved-range-of-motion.webp"
        },
        {
            title: "Highly Specialized",
            description: "Each therapist is dedicated to furthering their knowledge and creating a craft of their own.",
            imageURL: "highly-specialized.webp"
        }
    ],
    footer: {
        address: "1 Meridian Blvd, Wyomissing, PA 19610, United States",
        email: "nathaniel@taylormanualtherapy.com",
        copyright: "Copyright © 2022 Taylor Manual Therapy - All Rights Reserved.",
        social: [
            {
                label: "facebook",
                icon: "",
                url: ""
            },
            {
                label: "instagram",
                icon: "",
                url: ""
            },
            {
                label: "twitter",
                icon: "",
                url: ""
            }
        ]
    }
}

export default Data;
import React, {useEffect} from 'react';
import './App.css';
import AppHeader from "./components/app-header/app-header";
import Hero from "./components/hero/hero";
import BookSession from "./components/book-session/book-session";
import Techniques from "./components/techniques/techniques";
import Therapists from "./components/therapists/therapists";
import {CircularProgress, CssBaseline, ThemeProvider, Typography} from "@mui/material";
import customTheme from "./themeOveride";
import AppContextProvider from "./contexts/GlobalContext";
import {QueryClient, QueryClientProvider, useQuery} from "react-query";
import {createGlobalStyle} from "styled-components";
//@ts-ignore
import {motion} from 'framer-motion/dist/framer-motion';
import {Variants} from "framer-motion";
import * as Styled from "./components/therapists/therapists-styled-comp";

const queryClient = new QueryClient();
const GlobalStyle = createGlobalStyle`
  body {
    background-image: url(${(props: any) => props.bgImageUrl});
  }
`

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <AppContent/>
        </QueryClientProvider>
    );
}

function AppContent() {
    const endpoint = "https://taylormanualtherapy.com/wordpress/graphql";
    const WP_GQL_QUERY = `{
          home: page(id: "cG9zdDo2") {
            homePageCustomFields {
              heroText
              heroDescription
              backgroundImage {
                mediaItemUrl
              }
            }
          },
          components: page(id: "cG9zdDoxMzk=") {
            sessionPricesComponentCustomFields {
              sessionsNote
              bookSessionButtonLabel
              bookingUrl
            }
            operationHoursCustomFields {
                phoneNumber
              }
            modalitiesCustomFields {
              modalitiesSectionTitle
            }
            therapistsComponentCustomFields {
                therapistsSectionTitle
              }
              affiliatesComponentCustomFields {
                affiliatesSectionTitle
              }
            footerCustomFields {
              address
              copyright
              email
            }
            headerCustomFields {
              siteName
              siteLogo {
                mediaItemUrl
              }
            }
          },
          sessionPrices {
            nodes {
              sessionPrice {
                fieldGroupName
                sessionLength
                sessionLengthLabel
                sessionPrice
                sessionPriceCurrencySymbol
              }
            }
          },
          modalities {
            nodes {
              title
              featuredImage {
                node {
                  mediaItemUrl
                  mediaDetails {
                    height
                    width
                  }
                }
              }
            }
          },
          therapists {
            nodes {
              title
              content
              therapistsCustomFields {
                degree
                fieldGroupName
                license
                licenseNumber
                other
                photo {
                  mediaItemUrl
                }
              }
            }
          },
          services {
            nodes {
              serviceCustomFields {
                serviceDescription
              }
              featuredImage {
                node {
                  id
                  mediaItemUrl
                }
              }
              title
            }
          },
          affiliates {
            nodes {
              id
              title
              featuredImage {
                node {
                  mediaItemUrl
                  mediaDetails {
                    height
                    width
                  }
                }
              }
              affiliateCustomFields {
                    affiliateUrl
                  }
            }
          }
      }
    `;

    const friendsContainerFM: Variants = {
        hidden: {opacity: 0},
        show: {
            opacity: 1,
            transition: {
                when: "beforeChildren",
                staggerChildren: .15
            }
        }
    }
    const affiliateFM: Variants = {
        hidden: {opacity: 0, y: 100},
        show: {opacity: 1, y: 0}
    }
    const headerMotion: Variants = {
        hidden: {opacity: 0},
        show: {
            opacity: 1,
            transition: {
                duration: .5
            }
        }
    }

    const {data, isLoading, error} = useQuery("launches", () => {
        return fetch(endpoint, {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({query: WP_GQL_QUERY})
        })
            .then((response) => {
                if (response.status >= 400) {
                    throw new Error("Error fetching data");
                } else {
                    return response.json();
                }
            })
            .then((data) => data.data);
    });

    // useEffect(() => {
    //     if (isLoading) console.log("Loading...");
    //     //@ts-ignore
    //     if (error) console.log(error.message);
    //     if (data) {
    //         setHomePageData(data.pages.nodes.filter((node: any ) => node.slug == 'footer')[0]);
    //         setComponentsData(data.globalComponents.nodes.filter((node: any ) => node.slug == 'header')[0]);
    //     }
    // }, [data]);

    return (
        <ThemeProvider theme={customTheme}>
            <CssBaseline/>
            <GlobalStyle bgImageUrl={data && data.home.homePageCustomFields.backgroundImage.mediaItemUrl}/>
            <AppContextProvider>
                {
                    isLoading &&
                    <div className={"dataLoading"}>
                        <CircularProgress/> <Typography variant={"h3"}>loading</Typography>
                    </div>
                }
                {
                    error &&
                    <div className={"dataLoadingError"}>
                        <Typography variant={"h1"}>
                            Oops!
                        </Typography>
                        <Typography variant={"h2"}>
                            We've suffered a sprain.
                        </Typography>
                        <p>We're massaging it out to get the site back up and running ASAP! Please check back later.</p>
                        <p>If you need to book a session, <a href={"https://taylormanualtherapy.janeapp.com/"}
                                                             target={"_blank"}>please go here.</a></p>
                    </div>
                }
                {
                    data &&
                    <div className="App">
                        <AppHeader data={data.components.headerCustomFields}/>

                        <div className="app-body">
                            <Hero data={data.home.homePageCustomFields}/>
                            <BookSession
                                copy={data.components.sessionPricesComponentCustomFields}
                                prices={data.sessionPrices.nodes}
                                statusData={data.components.operationHoursCustomFields}
                            />
                            <Techniques
                                modalities={data.modalities.nodes}
                                title={data.components.modalitiesCustomFields.modalitiesSectionTitle}
                            />
                            <Therapists
                                sectionTitle={data.components.therapistsComponentCustomFields.therapistsSectionTitle}
                                therapists={data.therapists.nodes}
                                services={data.services.nodes}
                            />

                            <div className="friends-container">
                                <motion.div
                                    variants={headerMotion}
                                    initial="hidden"
                                    whileInView="show"
                                    viewport={{once: true, amount: 1}}
                                >
                                    <Typography
                                        variant="h2">
                                        {data.components.affiliatesComponentCustomFields.affiliatesSectionTitle}
                                        <span className="brandPeriod">.</span>
                                    </Typography>
                                </motion.div>

                                <motion.div className="friends"
                                            variants={friendsContainerFM}
                                            whileInView="show"
                                            initial={"hidden"}
                                            viewport={{once: true, amount: .35}}
                                >
                                    {
                                        React.Children.toArray(
                                            data.affiliates.nodes.map((affiliate: any) => {
                                                return (
                                                    <motion.div
                                                        variants={affiliateFM}
                                                    >
                                                        <a
                                                            className="friend"

                                                            href={affiliate.affiliateCustomFields.affiliateUrl}
                                                            target="_blank"
                                                        >
                                                        <span>
                                                            <span>
                                                                <img src={affiliate.featuredImage.node.mediaItemUrl}
                                                                     width={affiliate.featuredImage.node.mediaDetails.width}
                                                                     height={affiliate.featuredImage.node.mediaDetails.height}
                                                                     alt={affiliate.title}/>
                                                             </span>
                                                         </span>
                                                            <p>{affiliate.title}</p>
                                                        </a>
                                                    </motion.div>
                                                )
                                            })
                                        )
                                    }
                                </motion.div>
                            </div>
                        </div>

                        <div className="app-footer">
                            <div className="address">{data.components.footerCustomFields.address}</div>
                            <div className="email">
                                <a
                                    rel=""
                                    role="link"
                                    href={`mailto:${data.components.footerCustomFields.email}`}
                                    target="_blank"
                                >
                                    {data.components.footerCustomFields.email}
                                </a>
                            </div>
                            <div className="copyright">{data.components.footerCustomFields.copyright}</div>
                        </div>
                    </div>
                }
            </AppContextProvider>
        </ThemeProvider>
    )
}

export default App;
